import { Pages_Homepage as Homepage } from './Pages/Homepage/homepage'
import { Pages_About as About } from './Pages/About/about'
import { Pages_Services as Services } from './Pages/Services/services'
import { Pages_Clients as Clients } from './Pages/Clients/clients'
import { Pages_Team as Team } from './Pages/Team/team'
import { Pages_Contact as Contact } from './Pages/Contact/contact'
import { Pages_PrivacyPolicy as PrivacyPolicy } from './Pages/PrivacyPolicy/privacypolicy'
import { Pages_Company_Incorporation as CompanyIncorporation } from './Pages/Services/SubPages/company_incorporation'
import { Pages_Company_Secretarial as CompanySecretarial } from './Pages/Services/SubPages/company_secretarial'
import { Pages_Accounting as Accounting } from './Pages/Services/SubPages/accounting'
import { Pages_Audit as Audit } from './Pages/Services/SubPages/audit'
import { Pages_Tax as Tax } from './Pages/Services/SubPages/tax'
import { Pages_Financial as Financial } from './Pages/Services/SubPages/financial'
import { Pages_Trademark as Trademark } from './Pages/Services/SubPages/trademark'
import { Pages_Payroll as Payroll } from './Pages/Services/SubPages/payroll'
import { Pages_Permit as Permit } from './Pages/Services/SubPages/permit'
import { Pages_Communication as Communication } from './Pages/Services/SubPages/communication'

import { ParentMeta } from './Components/Metatags/ParentMeta/ParentMeta'
import { Whatsapp } from './Components/Sticky/Whatsapp/Whatsapp'

import { 
  ReactGA, 
  Route, 
  Routes,
  BrowserRouter,
} from './Constants/libraries'

import './App.css'
import 'antd/dist/antd.min.css'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import 'react-vertical-timeline-component/style.min.css'

//Google Analytics
ReactGA.initialize("G-BXNV6YJDSE")
ReactGA.send("pageview")

function App() {
  return (
    <BrowserRouter>
      <ParentMeta prefix=""/>
      
      <div className="App">
        <Routes>
          {/* Main Routes */}
          <Route path="/" element={<Homepage />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/team" element={<Team />} />
          <Route path="/partners" element={<Clients />} />
          <Route path="/contact" element={<Contact />} />

          {/* Sub Routes - Services */}
          <Route path="/services/company-incorporation" element={<CompanyIncorporation />} />
          <Route path="/services/company-secretarial" element={<CompanySecretarial />} />
          <Route path="/services/accounting" element={<Accounting />} />
          <Route path="/services/audit" element={<Audit />} />
          <Route path="/services/tax" element={<Tax />} />
          <Route path="/services/financial" element={<Financial />} />
          <Route path="/services/trademark" element={<Trademark />} />
          <Route path="/services/payroll" element={<Payroll />} />
          <Route path="/services/permit" element={<Permit />} />
          <Route path="/services/communication" element={<Communication />} />

          {/* Other Routes */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>

        {/* Sticky Icons */}
        <Whatsapp />
      </div>
    </BrowserRouter>
  );
}

export default App;
