import { Footer } from "../../../Components/Footer/Footer"
import { Navbar } from "../../../Components/Navbar/Navbar"
import { Financial } from "../../../Components/Services/Components/Financial/Financial"

export const Pages_Financial = () => {
    return (
        <>
            <Navbar />
            <Financial />
            <Footer />
        </>
    )
}