import { useState } from "react"

import {
    partners,
    title
} from "./Constants/index"
import { 
    Carousel, 
    useMediaQuery 
} from "../../Constants/libraries"
import { ParentMeta } from "../Metatags/ParentMeta/ParentMeta"
import { Loader } from "../Loader/Loader"

import "./Clients.css"

export const Clients = () => {

    const [documentReady, setDocumentReady] = useState(false)

    const handleOnLoad = () => setDocumentReady(true)
    const tablet_small = useMediaQuery({ query: '(max-width: 600px)' })    
    const tablet = useMediaQuery({ query: '(max-width: 780px)' })
    const tablet_large = useMediaQuery({ query: '(max-width: 1250px)' })    
    const imageWidth = 
        tablet_large ? "150px" :
            tablet ? "100px" :
                tablet_small ? "70px" 
                    : "200px"

    const partners_filtered = partners.filter((item:any) => item.carousel === true)
    const backdrop = tablet ? "https://smscs-frontend.s3.ap-southeast-1.amazonaws.com/Clients/clients_backdrop_mobile.png" : "https://smscs-frontend.s3.ap-southeast-1.amazonaws.com/Clients/clients_backdrop.png"

    return (
        <div className="Clients">
            {/* Indicators */}
            <ParentMeta prefix="Our Partners"/>
            {!documentReady && <Loader />}
            <img
                className="hide"
                src={backdrop}
                alt="Loader" 
                onLoad={handleOnLoad} 
            />
            <div 
                className="Clients__Title"
                id=">"
                style={{
                    background: "#000000",
                    backgroundImage: `url(${backdrop})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundAttachment: "fixed"
                }}
            >
                <p>{title}</p>
            </div>
            <div className="Clients__Wrapper">
                {partners.map((item, index) => (
                    <div>
                        <img
                            key={index}
                            src={item.logo}
                            alt={item.alt}
                            width={item.width ? item.width : imageWidth}
                        />
                    </div>
                ))}
            </div>
            <div>
                <Carousel 
                    autoPlay={true}
                    infiniteLoop={true}
                    stopOnHover={true}
                    interval={2500}
                >
                    {partners_filtered.map((item, index) => (
                        <div key={index} className="Clients__Carousel">
                            <p>{item.text}</p>
                            <p className="Clients__Carousel__Author">{`- ${item.author}`}</p>
                        </div>
                    ))}
                </Carousel>
            </div>
        </div>
    )
}