import { Footer } from "../../../Components/Footer/Footer"
import { Navbar } from "../../../Components/Navbar/Navbar"
import { CompanyIncorporation } from "../../../Components/Services/Components/CompanyIncorporation/CompanyIncorporation"

export const Pages_Company_Incorporation = () => {
    return (
        <>
            <Navbar />
            <CompanyIncorporation />
            <Footer />
        </>
    )
}