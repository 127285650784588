import { useState } from "react"
import { 
    about_tagline,
    about_heading_title,
    about_heading_p1, 
    about_heading_p2,
    about_heading_p3,
    about_tab_content_p1,
    about_tab_content_p2,
    about_tab_content_p3,
    about_tab_content_second_p1,
    about_tab_content_second_p2,
    about_tab_content_second_p3,
    about_tagline_alt
} from "./Constants"
import { 
    Button, 
    HashLink, 
    Tabs, 
    useMediaQuery 
} from "../../Constants/libraries"
import { Loader } from "../Loader/Loader"
import { ExpandMore } from "../General/ExpandMore/ExpandMore"
import { ParentMeta } from "../Metatags/ParentMeta/ParentMeta"

import "./About.css"
import TEST from "./1.jpg"

export const About = () => {

    const [documentReady, setDocumentReady] = useState(false)
    const { TabPane } = Tabs
    const about_heading = [about_heading_p1, about_heading_p2, about_heading_p3]

    const tablet = useMediaQuery({ query: '(max-width: 780px)' })
    const tablet_large = useMediaQuery({ query: '(max-width: 1250px)' })
    const background = tablet ? "https://smscs-frontend.s3.ap-southeast-1.amazonaws.com/About/About_Backdrop.jpg" : "https://smscs-frontend.s3.ap-southeast-1.amazonaws.com/About/About_Backdrop.jpg"
    const url = "https://smscs-frontend.s3.ap-southeast-1.amazonaws.com/About/about_content.jpg"

    const handleOnLoad = () => setDocumentReady(true)

    return (
        <div 
            className="About"
            id=">"
            style={{
                background: "#000000",
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundAttachment: "fixed"
            }}
        >
            <ParentMeta prefix="About Us"/>

            {/* Loader */}
            {!documentReady && <Loader />}
            <img
                className="hide"
                src={background}
                alt="Loader" 
                onLoad={handleOnLoad} 
            />

            {/* Title/Intro */}
            <div className="About__Intro">
                <div className="About__Intro__Overlay"></div>
                <div className="About__Intro__Overlay-cancel">
                    <p>About Us</p>
                    <p>{about_tagline}</p>
                </div>
            </div>

            {/* Heading */}
            <div className="About__Content">
                <div className="About__Content__Display-flex">
                    <div className="About__Content__Display-flex__Image">
                        <img
                            src={url}
                            alt={"Content"}
                        />
                    </div>
                    <div className="About__Content__Heading">
                        <div className="About__Content__Heading__Wrapper">
                            <p>{about_heading_title}</p>
                            {tablet ? 
                                <ExpandMore limit={2} paragraph={about_heading} fontColor={"black"}/>
                            :
                                <>
                                    {about_heading.map((item, index) => (
                                        <p key={index}>{item}</p>
                                    ))}
                                </>
                            }
                        </div>
                    </div>
                </div>

                {/* Page Break */}
                <div className="About__Content__Break">
                    <div className="About__Content__Break__Overlay"></div>
                    <div className="About__Content__Break__Overlay-cancel">
                        <p>{about_tagline_alt}</p>
                    </div>
                </div>

                {/* Tabs */}
                <div className="About__Content__Tabs">
                    <Tabs
                        defaultActiveKey="1" 
                        size="large"
                        tabPosition={tablet_large ? "top" : "right"} 
                    >
                        <TabPane tab="Why Register A Company in Malaysia" key="3">
                            <div className="About__Content__Tabs-text">
                                <p>{about_tab_content_p1}</p>
                                <p>{about_tab_content_p2}</p>
                                <p>{about_tab_content_p3}</p>
                            </div>
                        </TabPane>
                        <TabPane tab="Getting Started" key="4">
                            <div className="About__Content__Tabs-text">
                                <p>{about_tab_content_second_p1}</p>
                                <p>{about_tab_content_second_p2}</p>
                                <p>{about_tab_content_second_p3}</p>
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
                
                {/* End */}
                <div className="About__Content__End">
                    <hr></hr>
                    <Button className="About__Button">
                        <HashLink smooth to="/services#>">SERVICES</HashLink>
                    </Button>
                </div>
            </div>
        </div>
    )
}