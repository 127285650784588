import { Footer } from "../../../Components/Footer/Footer"
import { Navbar } from "../../../Components/Navbar/Navbar"
import { Permit } from "../../../Components/Services/Components/Permit/Permit"

export const Pages_Permit = () => {
    return (
        <>
            <Navbar />
            <Permit />
            <Footer />
        </>
    )
}