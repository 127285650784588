import ICON_WHATSAPP from "./Icons/footer_whatsapp.png";
import "./Whatsapp.css";

export const Whatsapp = () => {
    return (
        <div className="Whatsapp">
            <a href="https://wa.me/60122936614" target="_blank" rel="noreferrer">
                <img 
                    src={ICON_WHATSAPP} 
                    alt={"Whatsapp Icon"}
                    width={50}
                />
            </a>
        </div>
    )
}