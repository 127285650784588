export const title = `ACCOUNTING AND COMPANY`

export const content_first_p1 = `
    Maintaining proper track of your payments and transactions is the first step to increasing efficiency and giving you a clear picture of your financial performance. One of the main problems faced by many business owners is finding reliable accounting staff with knowledge of their business. Most of the time to retain them will be another problem. This is where we come in to help your business smoothly flow. 
`

export const content_first_p2 = `
    At our company, we have solutions whether it is a sole proprietorship, partnership, private limited companies, or limited liability partnership. We can take care of your business accounts and bookkeeping based on your requirements. In fact, outsourcing your accounting job to us is cost-effective and relieves you from having headaches.
`

export const content_first_p3 = `
    You will have access to expert advice and confidence in financial reports that meet regulatory and compliance standards. You get a dedicated team who knows your business and how to apply the latest financial rules to your books and this allows you to focus on your core business. 
`

export const content_second_title = "Services"

export const content_second_bullets = [
    "Management Accounts", 
    "Book-keeping",
    "Profit and loss account /Income Statement", 
    "Balance sheet",
    "Cash flow statement", 
    "Account receivables", 
    "Account payables", 
    "Debtors and creditors' statements", 
    "General ledger", 
    "Estimated Tax Payable" 
]

export const content_third_title = `Benefits Of Outsourcing Accounting Services:`

export const content_third_bullets = [
    "Cost-Effective: there is no need for full-time bookkeeping staff as all your accounting requirements can be completed by us",
    "On-time Delivery: we work using sophisticated accounting software, and even update accounts after office hours"
]