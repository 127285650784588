export const navigation = [
    {
        "text": "HOME",
        "link": "/"
    },
    {
        "text": "ABOUT",
        "link": "/about"
    },
    {
        "text": "SERVICES",
        "link": "/services"
    },
    {
        "text": "WHY CHOOSE US",
        "link": "/team"
    },
    {
        "text": "PARTNERS",
        "link": "/partners"
    }
]