import LOGO_KRISTA from "../Images/krista_logo.png"
import LOGO_SHARIQ from "../Images/shariq_logo.png"
import LOGO_SONGKET from "../Images/songket_logo.png"
import LOGO_AMPAC from "../Images/ampac_logo.png"
import LOGO_BOMBAY from "../Images/bombay_logo.png"
import LOGO_CANDERIA from "../Images/canderia_logo.png"
import LOGO_INFRATECH from "../Images/infratech_logo.png"
import LOGO_MARCHESE from "../Images/marchese_logo.png"
import LOGO_OTICON from "../Images/oticon_logo.png"
import LOGO_SDC from "../Images/sdc_logo.png"
import LOGO_TAIYO from "../Images/taiyo_logo.png"
import LOGO_UNIMECH from "../Images/unimech_logo.png"
import LOGO_VELVET from "../Images/velvet_logo.png"
import LOGO_VERTICAL from "../Images/vertical_logo.png"


export const title = `Our Partners`

export const partners = [
    {
        logo: LOGO_SHARIQ,
        alt: "SHARIQ Partners Logo",
        text: `
            The team is made up of professional, highly skilled, and trained personnel with years of experience in producing the desired results.
        `,
        author: `Shariq Partners (M) Sdn Bhd`,
        carousel: true,
    },
    {
        logo: LOGO_KRISTA,
        alt: "KRISTA Logo",
        text: `
            The SMS team is professional and helps offer quick and efficient support whenever needed since our incorporation in 1996. The work is completed on time and there are constant updates along the way for our peace of mind.
        `,
        author: `Krista Education Sdn Bhd`,
        carousel: true,
    },
    {
        logo: LOGO_SONGKET,
        alt: "Songket Dunia Logo",
        text: `
            SMS Corporate Services represents among the most professional firms in Malaysia. They have been actively managing my companies for the past 10 years. I have always experienced great service, guidance, and prompt communication from their team. I would highly recommend them for anyone requiring consultancy, secretarial or accounting services in Malaysia.
        `,
        author: `Anjum & Sons`,
        carousel: true,
    },
    {
        logo: LOGO_AMPAC,
        alt: "AMPAC Logo",
        width: "150px"
    },
    {
        logo: LOGO_BOMBAY,
        alt: "BOMBAY Logo",
    },
    {
        logo: LOGO_CANDERIA,
        alt: "CANDERIA Logo",
        width: "150px"
    },
    {
        logo: LOGO_INFRATECH,
        alt: "INFRATECH Logo",
    },
    {
        logo: LOGO_MARCHESE,
        alt: "MARCHESE Logo",
        width: "300px"
    },
    {
        logo: LOGO_OTICON,
        alt: "OTICON Logo",
    },
    {
        logo: LOGO_SDC,
        alt: "SDC Logo",
    },
    {
        logo: LOGO_TAIYO,
        alt: "TAIYO Logo",
    },
    {
        logo: LOGO_UNIMECH,
        alt: "UNIMECH Logo",
    },
    {
        logo: LOGO_VELVET,
        alt: "VELVET Logo",
    },
    {
        logo: LOGO_VERTICAL,
        alt: "VERITCAL Logo",
    }
]