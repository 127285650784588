export const about_tagline = <>Over <span>30</span> Years of Experience</>

export const about_tagline_alt = `Ensuring Simplified & Transparent Organizations`

export const about_heading_title = `WHO WE ARE`

export const about_heading_p1 = `
    As a thriving provider of professional corporate secretarial service in Malaysia, to both private and listed companies. We strive to keep your business aligned with the Malaysian government’s corporate governance regulations and issues to international standards. Our services cover a full range of company secretarial services that will see businesses through new company formation, registration, and incorporation matters in Malaysia, to the requirements of corporate secretarial work and registered office facilities. 
`

export const about_heading_p2 = `
    We At SMS Corporate Services understand the importance of maintaining a seamless and efficient structural flow within your company. Our secretarial services aim to ensure that your company’s time and performance are fully optimized by handling your administrative processes, corporate compliances, and more. 	
`

export const about_heading_p3 = `
    With more than 30 years of experience in corporate secretarial practice, Our clients range from start-ups, through SMEs right up to large-scale listed companies. Our understanding of the latest statutory and regulatory requirements means that your company is well protected and up to all corporate governance requirements. 
`

export const about_tab_content_p1 = `
    Malaysia is on its way to transforming its economy and positioning itself as an investment destination. The World Bank in 2020 ranked Malaysia as the world's 12th easiest and friendliest place to do business out of 190 nations, highlighting its attractiveness among both locals and foreigners in setting up a company in Malaysia. 
`

export const about_tab_content_p2 = `
    Malaysia has emerged as one of the best environments for entrepreneurs in the region. Several things make Malaysia a great place to bring your business ideas to life, such as the country’s reduced tax rate for small-to-medium enterprises (SMEs).
`

export const about_tab_content_p3 = `
    Starting a company on your own can be an overwhelming and intimidating process. However, at SMS Corporate Services, we guide you in every step of the way of setting up a company in Malaysia.
`
export const about_tab_content_second_p1 = `
    Small and Medium Enterprises (SMEs) are the backbone of Malaysia’s economy and growth. Just like any other business in Malaysia, an SME starts with registration or incorporation.  
`
export const about_tab_content_second_p2 = `
    This process is available online with the Companies Commission of Malaysia (CCM) or Suruhanjaya Syarikat Malaysia (SSM). You can structure your SME as a sole proprietorship, partnership, LLP, Sdn Bhd, etc. Following registration, your SME needs approvals, licensing and permits.  
`
export const about_tab_content_second_p3 = `
    Professional incorporation firms such as us (SMS Corporate Services) offer an end to end convenient business registration and approval processes. Get a quote now. 
`