import { Helmet } from "../../../Constants/libraries"

type Props = {
    prefix?:string
}

export const ParentMeta:React.FC<Props> = (props) => {

    const title = "SMS Corporate Services"
    const formattedTitle = props.prefix ? (props.prefix + " | " + title) : title

    return (
        <Helmet>
            <title>{formattedTitle}</title>
            <meta
                name="description"
                content="SMS Corporate Services. We provide corporate secretarial services for your company. Register a business in Malaysia today."
            />
            <meta
                name="keywords"
                content="SMSCS, Secretarial, Corporate, Business"
            />
        </Helmet>
    )
}