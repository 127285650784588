export const title = `COMPANY INCORPORATION & FOUNDATION SET UP`

export const content_first_title = `Private Limited Company also known as Sendirian Berhad (Sdn. Bhd.)`

export const content_first_p1 = `
    A Sdn Bhd company in Malaysia is a private limited business entity that can be started by both locals and foreigners. All businesses are required to be registered with the Companies Commission of Malaysia or Suruhanjaya Syarikat Malaysia (SSM). SSM is the governing body that oversees the companies operating in Malaysia.
`

export const content_first_p2 = `
    A Sdn. Bhd registration is one of the most popular legal business structure options in Malaysia regulated by The Companies Act 2016. To register a Sdn Bhd, a minimum of one director who primarily resides in Malaysia and has a principal place of residence in Malaysia is required. However, we encourage a minimum of two directors with one who primarily resides in Malaysia and has a principal place of residence in Malaysia. Shareholders of the Sdn Bhd can be a legal entity or any natural person or persons.
`

export const content_second_title = `Procedures for registering a Sdn Bhd`

export const content_second_p1 = `
    COST: RM 2500.OO (Includes two name searches, certificate of incorporation, statutory books, and first-month retainer fee. This is a promotion cost and subject to end or vary without notice).
`

export const content_second_p2 = `
    The required forms from the link are to be filled up or an email with the required details to be sent. 
`

export const content_third_title = `Information Required for Private Limited Company Registration`

export const content_third_bullets = [
    "Copy of identity cards (I.C) or passports (for foreign partners)",
    "Proposed company names",
    "Proposed nature of business (object clause)",
    "Ordinary place of residence of every shareholder and director if different from I.C",
    "Details of percentage and number of shares to be held by every shareholder", 
    "Email addresses of every director" 
]

export const content_third_p1 = `
    Minimum of two natural persons, one of whom must be having a principal place of residence in Malaysia is recommended although a Sdn Bhd can be registered with one person. Proposed directors must not be declared bankrupt and must be of age 18 and above. For foreigners who require nominee directors, service is provided at a cost.  
`

export const content_third_p2 = `
    It is recommended that as many names are given and numbered according to preference to avoid waste of time. All given names will be checked for availability without any cost.  However, only two names that may be available will be searched for booking. Additional name searches will be at an additional cost. 
`

export const content_fourth_title = `Register your Sdn Bhd company with us`

export const content_fourth_bullets = [
    "Seamless and easy process",
    "No more in-person visits" ,
    "Ready for business within 3 to 5 working days",
    "Incorporate your Malaysia Sdn Bhd Company Online with us today"
]