import { Footer } from "../../Components/Footer/Footer"
import { Navbar } from "../../Components/Navbar/Navbar"
import { Team } from "../../Components/Team/Team"

export const Pages_Team = () => {
    return (
        <>
            <Navbar />
            <Team />
            <Footer />
        </>
    )
}