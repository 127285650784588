import { Clients } from "../../Components/Clients/Clients"
import { Footer } from "../../Components/Footer/Footer"
import { Navbar } from "../../Components/Navbar/Navbar"

export const Pages_Clients = () => {
    return (
        <>
            <Navbar />
            <Clients />
            <Footer />
        </>
    )
}