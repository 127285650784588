export const title = `TAX ADVISORY AND COMPLIANCE`

export const content_first_title = `
    Tax matters can be complicated and confusing. We can eliminate the hassle for you, with these taxation services: 
`

export const content_first_bullets = [
    "Company tax (both for small and big operations for a Sdn Bhd)",
    "Personal tax (local & expatriate for salaried personnel)",
    "Sole Proprietor and partnership for tax calculation and submission",
    "Tax planning and advisory" 
]