import SMS_LOGO_ICON from "../../Images/SMS-Logo-Icon.png"
import SMS_LOGO_NO_TAGLINE from "../../Images/SMS-Logo-NoTagline.png"

import { navigation } from "./Constants/index.js"
import { Link, useMediaQuery } from "../../Constants/libraries"
import "./Navbar.css"
import { NavbarMobile } from "./NavbarMobile/NavbarMobile"

type Props = {
    useLogoIcon?:boolean
}

export const Navbar:React.FC<Props> = (props) => {

    const tablet = useMediaQuery({ query: '(max-width: 1000px)' })

    return (
        <>
            {!tablet ?
                <div className="Navbar">
                    <div>
                        <Link to={"/"}>
                            {props.useLogoIcon ? 
                                <img 
                                    src={SMS_LOGO_ICON} 
                                    alt="SMSCS Logo"
                                    width={70}
                                />
                            : 
                                <img 
                                    src={SMS_LOGO_NO_TAGLINE} 
                                    alt="SMSCS Logo"
                                    width={120}
                                />
                            }
                        </Link>
                    </div>
                    <div className="Navbar__Navigation">
                        {navigation.map((item:{text:string, link:string}, id) => (
                            <Link key={id} to={item.link}>{item.text}</Link>
                        ))}
                    </div>
                    <div className="Navbar__Contact">
                        <Link to={"/contact"}>CONTACT US</Link>
                    </div>
                </div>
            :
                <NavbarMobile />
            }
        </>
    )
}