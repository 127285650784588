import { Footer } from "../../../Components/Footer/Footer"
import { Navbar } from "../../../Components/Navbar/Navbar"
import { CompanySecretarial } from "../../../Components/Services/Components/CompanySecretarial/CompanySecretarial"

export const Pages_Company_Secretarial = () => {
    return (
        <>
            <Navbar />
            <CompanySecretarial />
            <Footer />
        </>
    )
}