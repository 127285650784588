import { Footer } from "../../Components/Footer/Footer"
import { Navbar } from "../../Components/Navbar/Navbar"
import { Services } from "../../Components/Services/Services"

export const Pages_Services = () => {
    return (
        <>
            <Navbar />
            <Services />
            <Footer />
        </>
    )
}