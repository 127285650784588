import SMS_LOGO_LIGHT from "../../Images/SMS-Logo-Light.png"
import { Link, useMediaQuery } from "../../Constants/libraries"
import { 
    footer_contact,
    footer_copyright_main, 
    footer_quick_links, 
    footer_social,
} from "./Constants"
import "./Footer.css"

export const Footer = () => {
    const mobile = useMediaQuery({ query: '(max-width: 600px)' })

    return (
        <div className="Footer">
            
            {/* Copyright */}
            <div className="Footer__Copyright">
                <p>{footer_copyright_main}</p>
            </div>


            {/* Logo */}
            <img 
                src={SMS_LOGO_LIGHT} 
                alt="SMS Corporate Services Logo"
                width={mobile ? 190 : 300}
            />

            {/* Social */}
            <div className="Footer__Social">
                {footer_social.map((item, index) => (
                    <a key={index} href={item.link} target="_blank" rel="noreferrer">
                        <img 
                            key={index} 
                            src={item.icon} 
                            alt={item.alt}
                            width={mobile ? 40 :50}
                        />    
                    </a>
                ))}
            </div>

            {/* General */}
            <div className="Footer__Horizontal">
                <div className="Footer__Horizontal__Quick-links">
                    <p>Quick Links</p>
                    {footer_quick_links.map((item, index) => (
                        <Link key={index} to={item.link}>{item.title}</Link>
                    ))}
                </div>
                <div className="Footer__Horizontal__Contact">
                    <p>Contact</p>
                    {footer_contact.map((item, index) => (
                        <a key={index} href={item.link}>{item.title}</a>
                    ))}
                </div>
            </div>
        </div>
    )
}