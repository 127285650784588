export const title = `AUDIT SUPPORT SERVICES`

export const content_first_p1 = `
    Every Sdn Bhd company is required by the Companies Act 2016 to appoint an approved auditor for their companies to prepare, circulate, and file it with the authorities.  
`

export const content_first_p2 = `
    Whether active or dormant, all companies must have their accounts audited by the auditors annually within a stipulated time. Our company plays an important role to link you with reliable, approved, and compatible auditors to ensure that your accounts are properly audited for circulation and filing on time.  
`

export const content_first_p3 = `
    In our 30 years of experience, we have come across many auditors and have selected auditors who are easy to communicate with and reliable auditors whose commitment is to ensure customer data integrity and confidentiality with a timely status report as well as to provide cost-effective and accurate deliverables. 
`

export const content_second_title = `
    Type of Audit Services rendered are: 
`

export const content_second_bullets = [
    "Audit for Limited Company ",
    "Active and Dormant Company Audit (For Sdn Bhd)",
    "Audit Investigation (to achieve a particular objective if the need arises)"
]