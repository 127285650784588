import { useState } from "react"

import { 
    contact_info,
    error_message,
    nominee_director_message, 
    support_message
} from "./Constants/index"
import { 
    Alert,
    Button, 
    Chip, 
    FontAwesomeIcon, 
    Input, 
    Snackbar,
    useMediaQuery
} from "../../Constants/libraries"
import { Loader } from "../Loader/Loader"

import "./Contact.css"
import { ParentMeta } from "../Metatags/ParentMeta/ParentMeta"

export const Contact = () => {

    //Input field states
    const [values, setValues] = useState<any>({
        name: undefined,
        phone: undefined,
        email: undefined,
        proposed_company: undefined,
        nature_business: undefined,
        business_address: undefined,
        shareholder_name: undefined,
        director_name: undefined,
    })
    const [messageInput, setMessageInput] = useState<string>("")

    //Indicator states
    const [allowSend, setAllowSend] = useState(true)
    const [loader, setLoader] = useState(false)
    const [snackbar, setSnackbar] = useState(false)

    //Constants
    const { TextArea } = Input
    const phone = useMediaQuery({ query: '(max-width: 400px)' })
    const vertical = "bottom"
    const horizontal = "center"
    let form_fields = [
        {
            title: "Name *",
            id: "name",
        },
        {
            title: "Mobile Phone Number *",
            id: "phone",
        },
        {
            title: "Email Address *",
            id: "email",
        },
        {
            title: "Existing/Proposed Company Name",
            id: "proposed_company",
        },
        {
            title: "Nature of Business",
            id: "nature_business",
        },
        {
            title: "Business Address",
            id: "business_address",
        },
        {
            title: "Shareholder Name",
            id: "shareholder_name",
        },
        {
            title: "Director Name",
            id: "director_name",
        },
    ]

    const validateField = (itemId:string, index:number) => {
        if (itemId === "proposed_company" || itemId === "nature_business" || itemId === "business_address" || itemId === "shareholder_name" || itemId === "director_name") return false
        return ((values[itemId] !== undefined) && (values[itemId] === "")) 
    }

    const validateFields = () => {
        for (const [key, value] of Object.entries(values)) {
            if ((key !== "proposed_company" && key !== "nature_business" && key !== "business_address" && key !== "shareholder_name" && key !== "director_name") && (value === undefined || value === "")) {
                setAllowSend(false)
                return false
            }
            else {
                setAllowSend(true)
            }
        }
        return true;
    }

    const handleChange = (item:any, text:any) => {
        setValues({
            ...values,
            [item.id]: text
        })
    }

    const send = async () => {
        const validated = validateFields()

        if (validated) {
            try {
                setLoader(true)
                var finalString = {
                    name: values.name,
                    phone: values.phone,
                    email: values.email,
                    proposed_company: values.proposed_company,
                    nature_business: values.nature_business,
                    business_address: values.business_address,
                    shareholder_name: values.shareholder_name,
                    director_name: values.director_name,
                    message: messageInput    
                }
                await fetch("https://smscs-backend.herokuapp.com/email", {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    method: 'post',
                    body: JSON.stringify(finalString),
                }).then(() => {
                    console.log("Email Sent")
                    setLoader(false)
                    setSnackbar(true)
                    setTimeout(() => {
                        setSnackbar(false)
                        window.location.reload()
                    }, 3000)
                })
            }
            catch (e) {
                console.log("Email Error", e)
                setLoader(false)
            }
        }
        else return
    }

    return (
        <div className="Contact">
            <ParentMeta prefix="Contact Us"/>

            {/* Indications */}
            {loader && <Loader />}
            <Snackbar 
                open={snackbar} 
                onClose={() => setSnackbar(false)}
                anchorOrigin={{ vertical, horizontal }}
            >
                <Alert onClose={() => setSnackbar(false)} severity="success" sx={{ width: '100%' }}>
                    Message Sent!
                </Alert>
            </Snackbar>

            <div className="Contact__Info">

                {/* Header */}
                <p className="Contact__Title">Contact Us</p>

                <div className="Contact__Info__Chips">
                    {contact_info.map((item, index) => (
                        <Chip
                            // variant="outlined"
                            icon={<FontAwesomeIcon icon={item.logo} />}
                            label={
                                <a href={item.href} target="_blank" rel="noreferrer">{item.text}</a>
                            }
                            style={ phone ? {fontSize: "9px"} : {fontSize: "13px"}}
                        />
                    ))}
                </div>
            </div>

            <div className="Contact__Form">

                {/* Inputs */}
                {!allowSend && <p className="Contact__Errors">{error_message}</p>}
                <div className="Contact__Inputs">
                    {form_fields.map((item, index) => (
                        <Input
                            key={index}
                            allowClear
                            placeholder={item.title}
                            size="large"
                            status={validateField(item.id, index) ? "error" : ""}
                            onChange={(e) => handleChange(item, e.target.value)}
                        />
                    ))}
                    <TextArea
                        placeholder="Enter your message (Optional)"
                        rows={6}
                        allowClear
                        showCount
                        onChange={(e) => setMessageInput(e.target.value)}
                    />
                </div>
                <Button 
                    size="large"
                    onClick={send}
                >
                    SUBMIT
                </Button>

                {/* Outro */}
                <div className="Contact__Helper-text">
                    <p>{nominee_director_message}</p>
                    <p>{support_message}</p>
                </div>
            </div>
        </div>
    )
}