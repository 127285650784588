import { Footer } from "../../../Components/Footer/Footer"
import { Navbar } from "../../../Components/Navbar/Navbar"
import { Accounting } from "../../../Components/Services/Components/Accounting/Accounting"

export const Pages_Accounting = () => {
    return (
        <>
            <Navbar />
            <Accounting />
            <Footer />
        </>
    )
}