import { 
    Button, 
    faArrowLeftRotate, 
    FontAwesomeIcon, 
    HashLink, 
    useMediaQuery
} from "../../../../Constants/libraries"
import { 
    title,
    content_first_p1,  
    content_second_p1
} from "./Constants"
import { CTA } from "../../../CTA/CTA"

import "../../ServicesSubpages.css"

export const Trademark = () => {

    const tablet = useMediaQuery({ query: '(max-width: 780px)' })
    const background = tablet ? "https://smscs-frontend.s3.ap-southeast-1.amazonaws.com/Services/Services_All_Thumbnails_Resized/services_trademark.jpg" : "https://smscs-frontend.s3.ap-southeast-1.amazonaws.com/Services/Services_All_Thumbnail_Backgrounds/services_trademark_background.jpg"
    
    return (
        <>
            <div 
                id=">"
                className="Trademark Services__Subpage"
                style={{
                    background: "#000000",
                    backgroundImage: `url(${background})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                }}
            >
                <div className="Services__Subpage__Overlay"></div>

                <div className="Services__Subpage__Overlay-cancel">
                    {/* Content */}
                    {!tablet && 
                        <div className="Services__Subpage__Content">
                            <p>{content_first_p1}</p>
                            <hr />
                            <p>{content_second_p1}</p>
                            <hr />
                        </div>
                    }

                    {/* Empty div for flexbox */}
                    <div className="Services__Subpage__Content-flex">
                        <p>{title}</p>
                        <Button>
                            <HashLink smooth to="/services#>">
                                <span><FontAwesomeIcon icon={faArrowLeftRotate}/></span>
                                SERVICES
                            </HashLink>
                        </Button>
                    </div>
                </div>
            </div>

            {/* Content layout for mobile */}
            {tablet && 
                <div className="Services__Subpage__Content">
                    <p>{content_first_p1}</p>
                    <hr />
                    <p>{content_second_p1}</p>
                    <hr />
                </div>
            }

            {/* CTA */}
            <CTA />
        </>
    )
}