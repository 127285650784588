import { Footer } from "../../../Components/Footer/Footer"
import { Navbar } from "../../../Components/Navbar/Navbar"
import { Tax } from "../../../Components/Services/Components/Tax/Tax"

export const Pages_Tax = () => {
    return (
        <>
            <Navbar />
            <Tax />
            <Footer />
        </>
    )
}