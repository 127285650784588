import { useState } from "react"
import { 
    Button, 
    faAngleDown, 
    FontAwesomeIcon, 
    HashLink, 
    useMediaQuery 
} from "../../Constants/libraries"
import SMS_LOGO_MAIN from "../../Images/SMS-Logo-Icon.png";
import { Loader } from "../Loader/Loader";
import { companyName, headline, logoText, tagline } from "./Constants";

import "./Homepage.css"

export const Homepage = () => {

    const [isVideoLoaded, setIsVideoLoaded] = useState(false)

    const tablet = useMediaQuery({ query: '(max-width: 780px)' })
    const url = tablet ? "https://smscs-frontend.s3.ap-southeast-1.amazonaws.com/Homepage/homepage_video_mobile.mp4" : "https://smscs-frontend.s3.ap-southeast-1.amazonaws.com/Homepage/homepage_video_desktop.mp4"

    const onLoadedData = () => {
        setIsVideoLoaded(true)
    }

    return (
        <div className="Homepage">
            
            {/* Loader */}
            {!isVideoLoaded && <Loader />}

            {/* Background video */}
            <video 
                loop 
                autoPlay 
                muted
                onLoadedData={onLoadedData}
                style={{ opacity: isVideoLoaded ? 1 : 0}}
            >
                <source src={url} type="video/mp4"/>
                {"An Error Occured"}
            </video>

            {/* Overlay */}
            <div className="Homepage__Overlay"></div>

            {/* Content */}
            <div className="Homepage__Content" style={{ opacity: isVideoLoaded ? 1 : 0}}>
                <div className="Homepage__Content__Logo">
                    <img src={SMS_LOGO_MAIN} alt="SMSCS Logo" />
                    <p className="Homepage__Content__Logo__Title">{companyName}</p>
                    <p className="Homepage__Content__Logo__Subtitle">{logoText}</p>
                </div>
                <div>
                    {/* <p className="Homepage__Content__Header Homepage__Content__Headline">{headline}</p> */}
                    {/* <p className="Homepage__Content__Header">{tagline}</p> */}
                    {/* <Button shape="round" size="large">
                        <HashLink smooth to="/services#>">VIEW MORE</HashLink>
                    </Button>     */}
                </div>
                <HashLink smooth to="#>>>">
                    <FontAwesomeIcon icon={faAngleDown} />
                </HashLink>
            </div>
        </div>
    )
}