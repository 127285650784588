import { Footer } from "../../../Components/Footer/Footer"
import { Navbar } from "../../../Components/Navbar/Navbar"
import { Payroll } from "../../../Components/Services/Components/Payroll/Payroll"

export const Pages_Payroll = () => {
    return (
        <>
            <Navbar />
            <Payroll />
            <Footer />
        </>
    )
}