import { 
    faDollar,
    faStopwatch,
    faChartLine,
    faClipboardCheck,
    faPeopleLine
} from "../../../Constants/libraries"

import LOGO_LHDM from "../Images/lhdm_logo.png"
import LOGO_MIA from "../Images/mia_logo.jpg"
import LOGO_SSM from "../Images/ssm_logo.png"

export const title = `Our Team`

export const team_heading_title = `Why Choose Us`

export const team_heading_title_sub = `One-Stop Centre for All Your Business Needs`

export const team_heading_p1 = `
    Starting a new business is always a scary step. Aside from the intimidating prospect of taking that first step to launch and ‘go at it alone, there is a complex list of jargons, rules, and regulations to navigate.
`

export const team_heading_p2 = `
    That’s where we come in. Our mission at SMS Corporate Services is to expedite and simplify the entire process for you, from getting your chosen name registered to sort out your company registration and everything within the corporate ecosystem. We believe in clarity within regulations, our services ensure complete transparency and leave you free to run your business stress-free. 
`

export const team_content_title = `Our value proposition:`

export const team_content_bullets = [
    {
        title: "COST SAVING",
        content: "We offer highly competitive pricing along with the knowledge to help you ensure more savings for your business.",
        icon: faDollar
    },
    {
        title: "30+ YEARS OF EXPERIENCE",
        content: "Our company offers secretarial, accounts, and audit experience for Malaysian businesses of all sizes and industries.",
        icon: faStopwatch
    },
    {
        title: "SEAMLESS & EFFICIENT",
        content: "Just contact us via Whatsapp or complete the form on our website and our team will reach out and guide you through the necessary steps to get started.",
        icon: faClipboardCheck
    },
    {
        title: "QUALITY ASSURED",
        content: "Our company is compliant and up to standard with SSM (Companies Commission of Malaysia) and MAICSA (The Malaysian Institute of Chartered Secretaries and Administrators).",
        icon: faChartLine
    },
    {
        title: "WELL CONNECTED",
        content: "We offer an extensive network of partners for all your business compliance needs.",
        icon: faPeopleLine
    }
]

export const team_content_second_title = `Our Qualifications`

export const team_content_second_bullets = [
    "Licensed Secretary",
    "In Compliance with:"
]

export const team_content_second_extra = [
    {
        logo: LOGO_SSM,
        alt: "SSM LOGO",
        customClassname: "Team__Content__Images-ssm"
    },
    {
        logo: LOGO_LHDM,
        alt: "LHDM LOGO"
    },
    {
        logo: LOGO_MIA,
        alt: "MIA LOGO"
    },
]