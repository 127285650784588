import { 
    faPhone, 
    faMessage,
    faLocation 
} from "../../../Constants/libraries"

export const form_fields = [
    {
        title: "Name"
    },
    {
        title: "Mobile Phone Number"
    },
    {
        title: "Email Address"
    },
    {
        title: "Proposed Company Name"
    },
    {
        title: "Nature of Business"
    },
    {
        title: "Business Address"
    },
    {
        title: "Shareholder Name"
    },
    {
        title: "Director Name"
    },
]

export const contact_info = [
    {
        "logo": faMessage,
        "text": "sms.corp@yahoo.com",
        "href": "mailto:sms.corp@yahoo.com"
    },
    {
        "logo": faPhone,
        "text": "+603-4270 6677",
        "href": "tel:+60342706677"
    },
    {
        "logo": faPhone,
        "text": "+60 12-293 6614",
        "href": "tel:+60122936614"
    },
    {
        "logo": faLocation,
        "text": "B1-1, JALAN SELAMAN 1, DATARAN PALMA, AMPANG, MALAYSIA",
        "href": "https://maps.google.com/?q=5 B1-1, JALAN SELAMAN 1, DATARAN PALMA, AMPANG, MALAYSIA"
    }
]

export const nominee_director_message = "We also offer nominee director services, you can enquire by filling in \"Nominee Director\" in the field."
export const support_message = "Our support team will be in touch if further supporting documents are required."
export const error_message = "Please fill up all the required fields ( * )"
