export const title = `PAYROLL OUTSOURCING`

export const content_first_p1 = `
    We have the expertise and credibility to handle your payroll solutions and to address all your compliance and regulation needs and requirements. Outsourcing your company’s payroll service enables you to save costs on hiring full-time payroll personnel and some sophisticated software purchase and maintenance. Most of all it takes away your stress. 
`

export const content_second_title = `
    Payroll services include the following: 
`

export const content_second_bullets = [
    "PAYROLL SUMMARY AND MONTHLY STAFF SALARY SLIP (MONTHLY AND ANNUAL PAY REGISTER RECORD)", 
    "BORANG EA FOR EVERY EMPLOYEE (YEAR-END REPORT AND FOR INDIVIDUAL TAX SUBMISSION)", 
    "ALLOWANCE AND OVERTIME DETAILS", 
    "EPF DEDUCTION AND MONTHLY SUBMISSION", 
    "PERKESO AND EIS CALCULATION DEDUCTION AND PAYMENT SUBMISSION", 
    "PCB CALCULATION, DEDUCTION, AND PAYMENT (This is an additional service with a fee based on   the number of employees)" 
]

export const content_second_p2 = `
    (Additional charges apply for new companies which have not registered for all the above services) 
`

export const content_second_p3 = `
    We also handle new hires and termination. 
`