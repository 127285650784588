import { CTA } from "../../Components/CTA/CTA"
import { Footer } from "../../Components/Footer/Footer"
import { HomepageContent } from "../../Components/Homepage/Components/HomepageContent/HomepageContent"
import { Homepage } from "../../Components/Homepage/Homepage"
import { Navbar } from "../../Components/Navbar/Navbar"

export const Pages_Homepage = () => {
    return (
        <>
            <Navbar />
            <Homepage />
            <HomepageContent />
            <CTA includeImage={true} />
            <Footer />
        </>
    )
}