import { useState } from "react"

import { 
    Button, 
    HashLink, 
    useMediaQuery 
} from "../../Constants/libraries"
import { Loader } from "../Loader/Loader"
import { ParentMeta } from "../Metatags/ParentMeta/ParentMeta"
import { ServicesMap } from "./Components/ServicesMap/ServicesMap"
import { ServicesMapMobile } from "./Components/ServicesMapMobile/ServicesMapMobile"
import { services_ending, services_heading } from "./Constants/index.js"

import "./Services.css"

export const Services = () => {

    const [documentReady, setDocumentReady] = useState(false)

    const tablet = useMediaQuery({ query: '(max-width: 780px)' })
    const background = tablet ? "https://smscs-frontend.s3.ap-southeast-1.amazonaws.com/Services/services_backdrop_mobile.png" : "https://smscs-frontend.s3.ap-southeast-1.amazonaws.com/Services/services_backdrop.png"

    const handleOnLoad = () => setDocumentReady(true)

    return (
        <div className="Services" id=">">
            <ParentMeta prefix="Services"/>

            {/* Loader */}
            {!documentReady && <Loader />}
            <img
                className="hide"
                src={background}
                alt="Loader" 
                onLoad={handleOnLoad} 
            />

            <div 
                className="Services__Title"
                id=">"
                style={{
                    background: "#000000",
                    backgroundImage: `url(${background})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundAttachment: "fixed"
                }}
            >
                <p>Our Corporate Services</p>
                <p>{services_heading}</p>
            </div>
            {!tablet ? <ServicesMap /> :<ServicesMapMobile />}

            <div className="Services__End">
                <p>{services_ending}</p>
                <Button className="Services__Button">
                    <HashLink smooth to="/contact#>">CONTACT</HashLink>
                </Button>            
            </div>
        </div>
    )
}