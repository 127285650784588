import FOOTER_WHATSAPP from "../Icons/footer_whatsapp.png"
import FOOTER_LINKEDIN from "../Icons/footer_linkedin.png"
import FOOTER_FACEBOOK from "../Icons/footer_facebook.png"

export const footer_social = [
    {
        icon: FOOTER_WHATSAPP,
        link: "https://wa.me/60122936614",
        alt: "Whatsapp"
    },
    {
        icon: FOOTER_LINKEDIN,
        link: "https://www.linkedin.com/company/sms-corporate-services-sdn-bhd/",
        alt: "LinkedIn"
    },
    {
        icon: FOOTER_FACEBOOK,
        link: "https://www.facebook.com/smscorporate",
        alt: "Facebook"
    }
]

export const footer_quick_links = [
    {
        title: "Homepage",
        link: "/"
    },
    {
        title: "Privacy Policy",
        link: "/privacy-policy"
    },
    {
        title: "Contact Us",
        link: "/contact"
    }
]

export const footer_contact = [
    {
        title: "+60 12-293 6614",
        link: "tel:+60122936614"
    },
    {
        title: "+603-4270 6677",
        link: "tel:+60342706677"
    },
    {
        title: "sms.corp@yahoo.com",
        link:"mailto:sms.corp@yahoo.com"
    }
]

export const footer_copyright_main =  "All Rights Reserved © 2022 SMS Corporate Services"