import { useState } from "react"

import { 
    FontAwesomeIcon, 
    useMediaQuery, 
    VerticalTimeline, 
    VerticalTimelineElement 
} from "../../Constants/libraries"
import { 
    team_heading_p1,
    team_heading_p2,
    team_heading_title,
    team_heading_title_sub,
    team_content_bullets,
    team_content_title,
    team_content_second_title,
    team_content_second_bullets,
    team_content_second_extra
} from "./Constants"
import { ExpandMore } from "../General/ExpandMore/ExpandMore"
import { Loader } from "../Loader/Loader"
import { ParentMeta } from "../Metatags/ParentMeta/ParentMeta"

import "./Team.css"

export const Team = () => {

    const [documentReady, setDocumentReady] = useState(false)
    const tablet = useMediaQuery({ query: '(max-width: 780px)' })
    const background = "https://smscs-frontend.s3.ap-southeast-1.amazonaws.com/Team/Background_Darkened/team_background_resized-min-1.png"
    const team_heading = [team_heading_p1, team_heading_p2]

    const handleOnLoad = () => setDocumentReady(true)

    return (
        <div 
            className="Team"
            id=">"
            style={{
                background: "#000000",
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundAttachment: "fixed"
            }}
        >
            <ParentMeta prefix="Our Team"/>

            {/* Loader */}
            {!documentReady && <Loader />}
            <img
                className="hide"
                src={background}
                alt="Loader" 
                onLoad={handleOnLoad} 
            />

            {/* <p>{title}</p> */}

            {/* First */}
            <div className="Team__Content Team__Content__Custom-background">
                <p className="Team__Content__Title title-orange">{team_heading_title}</p>
                <p className="Team__Content__Title-sub">{team_heading_title_sub}</p>
            </div>

            {/* Second */}
            <div className="Team__Content Team__Content__Second-background">
                <p className="Team__Content__Title Team__Content__Title-second">{team_content_title}</p>
                {tablet ?
                    <ExpandMore fontColor={"black"} limit={2} paragraph={team_heading}/>
                :
                    <div className="Team__Content__Text">
                        {team_heading.map((item, index) => (
                            <p key={index}>{item}</p>
                        ))}
                    </div>
                }
                <VerticalTimeline className="Team__Content__Timeline-wrapper">
                    {team_content_bullets.map((item, index) => (
                        <VerticalTimelineElement
                            key={index}
                            iconStyle={{ opacity: 1, color: "#cccccc"}}
                            icon={<FontAwesomeIcon icon={item.icon} />}
                            date={item.title}
                        >
                            <p>{item.content}</p>
                        </VerticalTimelineElement>
                    ))}
                </VerticalTimeline>
            </div>

            {/* Third */}
            <div className="Team__Content Team__Content__Custom-background-second">
                <p className="Team__Content__Title">{team_content_second_title}</p>
                <ul className="Team__Content__Title-sub Team__Content__Custom-title-sub">
                    {team_content_second_bullets.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
                <div className="Team__Content__Images">
                    {team_content_second_extra.map((item, index) => (
                        <img 
                            key={index}
                            className={item.customClassname ? item.customClassname : ""}
                            src={item.logo} 
                            alt={item.alt}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}