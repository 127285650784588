import { HashLink } from "../../../../Constants/libraries"
import { services } from "../../Constants"
import "./ServicesMapMobile.css"

export const ServicesMapMobile = () => {
    return (
        <div className="Services__Description-Mobile">
            {services.map((item, index) => (
                <div className={`Services__Description-Mobile__Wrapper ${index === 0 ? "remove-margin" : ""}`}>
                    {/* Image */}
                    <div 
                        key={index}
                        className="Services__Description-Mobile__Wrapper__Image"
                        style={{
                            background: "#000000",
                            backgroundImage: `url(${item.img}`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: item.img_alignment ? item.img_alignment : "center",
                            backgroundSize: "cover",
                        }}
                    >

                        {/* Overlay */}
                        <div className="Services__Description-Mobile__Wrapper__Image__Overlay"></div>

                        {/* Content */}
                        <div className="Services__Description-Mobile__Wrapper__Image__Overlay-cancel">
                            <p>{item.title}</p>
                            <div className="Services__Description-Mobile__Wrapper__Content">
                                <p>{item.description}</p>
                                <HashLink smooth to={`/services${item.link}#>`}>View More</HashLink>
                            </div>
                        </div>      
                    </div>
                </div>
            ))}   
        </div>
    )
}