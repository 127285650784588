import { 
    Button, 
    faArrowLeftRotate, 
    FontAwesomeIcon, 
    HashLink, 
    useMediaQuery
} from "../../../../Constants/libraries"
import { 
    title,
    content_first_title,
    content_first_bullets
} from "./Constants"
import { CTA } from "../../../CTA/CTA"

import "../../ServicesSubpages.css"
import "./Tax.css"

export const Tax = () => {

    const tablet = useMediaQuery({ query: '(max-width: 780px)' })
    const background = tablet ? "https://smscs-frontend.s3.ap-southeast-1.amazonaws.com/Services/Services_All_Thumbnails_Resized/services_tax.jpg" : "https://smscs-frontend.s3.ap-southeast-1.amazonaws.com/Services/Services_All_Thumbnail_Backgrounds/services_tax_background.jpg"

    return (
        <>
            <div 
                id=">"
                className="Tax Services__Subpage"
                style={{
                    background: "#000000",
                    backgroundImage: `url(${background})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                }}
            >
                <div className="Tax__Overlay__Overwrite Services__Subpage__Overlay"></div>

                <div className="Services__Subpage__Overlay-cancel">

                    {/* Content */}
                    {!tablet && 
                        <div className="Services__Subpage__Content">
                            <h1>{content_first_title}</h1>
                            <ul>
                                {content_first_bullets.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                            <hr />
                        </div>
                    }

                    {/* Empty div for flexbox */}
                    <div className="Services__Subpage__Content-flex">
                        <p>{title}</p>
                        <Button>
                            <HashLink smooth to="/services#>">
                                <span><FontAwesomeIcon icon={faArrowLeftRotate}/></span>
                                SERVICES
                            </HashLink>
                        </Button>
                    </div>
                </div>
            </div>

            {/* Content layout for mobile */}
            {tablet && 
                <div className="Services__Subpage__Content">
                    <h1>{content_first_title}</h1>
                    <ul>
                        {content_first_bullets.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                    <hr />
                </div>
            }

            {/* CTA */}
            <CTA />
        </>
    )
}