import { useState } from "react"
import { 
    faAngleDown, 
    faAngleUp, 
    FontAwesomeIcon, 
} from "../../../Constants/libraries"

import "./ExpandMore.css"

type Props = {
    paragraph:string[]
    limit:number,
    fontColor?:string
}

export const ExpandMore:React.FC<Props> = (props) => {

    const [expand, setExpand] = useState(false)

    const handleOnClick = () => setExpand(!expand)

    return (
        <div style={props.fontColor ? { color: "black" } : { color: "white" }}>
            <p>{props.paragraph[0]}</p>
            <div style={expand ? { display: "block" } : { display: "none" }}>
                {props.paragraph.slice(1, props.limit + 1).map((item, index) => (
                    <p key={index}>{item}</p>
                ))}
            </div>
            <p className="ExpandMore" onClick={handleOnClick}>
                {expand ?
                    <div style={{marginTop: "1em"}}>
                        <span className="ExpandMore__Click">See Less</span>
                        <span><FontAwesomeIcon icon={faAngleUp}/></span> 
                    </div>
                :
                    <>
                        <span className="ExpandMore__Click">See More</span>
                        <span><FontAwesomeIcon icon={faAngleDown}/></span> 
                    </>
                }
            </p>
        </div>
    )
}

