export const title = `WTA WORK PERMIT SERVICES`

export const content_first_p1 = `
    The Employment Pass (EP) is a work permit that enables an expatriate to take up employment with an organization in Malaysia. This pass is subject to a contract of employment for up to 60 months depending on the employment contract and the Expatriate Committee (EC). The expatriate can also register his own eligible company to apply for an Expatriate Pass. 
`

export const content_second_p1 = `
    The criteria and requirements for the application shall be further discussed once the decision is reached. 
`

