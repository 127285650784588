export const title = `COMPANY SECRETARIAL`

export const content_first_p1 = `
    We have licensed secretaries perfect for all businesses, irrespective of company size or structure. We provide comprehensive corporate secretarial services. Our team of professional Chartered Secretaries provides relevant advisory services to help you achieve your company's needs and compliance in a timely and cost-effective manner.
`

export const content_second_title = `Have no worries about your Company’s corporate secretary:`

export const content_second_bullets = [
    "Preparation and filing of all statutory forms, including confirmation statements, minutes of meetings, and circular and written Resolutions",
    "Maintain a registered office for your company",
    "Appointment and resignation of directors and changes in corporate structure",
    "Maintenance of Minutes, Book, and Members Register",
    "Extracting and certification of forms and documents when required",
    "Change of Company names, Audit year-end, and Company Secretaries"
]