import { Footer } from "../../../Components/Footer/Footer"
import { Navbar } from "../../../Components/Navbar/Navbar"
import { Trademark } from "../../../Components/Services/Components/Trademark/Trademark"

export const Pages_Trademark = () => {
    return (
        <>
            <Navbar />
            <Trademark />
            <Footer />
        </>
    )
}