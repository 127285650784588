import { ClipLoader } from "../../Constants/libraries"
import SMS_LOGO_ICON from "../../Images/SMS-Logo-Icon.png"
import "./Loader.css"

export const Loader = () => {

    const loader_styles = {
        borderWidth: "4px"
    }
    
    return (
        <div className="Loader">

            {/* Loader Icon */}
            <div className="Loader__Icon">
                <ClipLoader 
                    color={"#007979"} 
                    loading={true} 
                    size={200}
                    speedMultiplier={0.6}
                    cssOverride={loader_styles}
                />
            </div>

            {/* Logo */}
            <img src={SMS_LOGO_ICON} alt={"SMSCS Logo"}/>

            <p>Loading</p>
        </div>
    )
}