import { useState } from "react";
import { Alert, Button, Input, Snackbar } from "../../Constants/libraries";
import { Loader } from "../Loader/Loader";
import "./CTA.css";

interface Fields {
    name:string,
    company:string,
    phone:string,
    email:string,
    message:string
}


export const CTA = ({ includeImage = false }) => {
    const { TextArea } = Input
    const url = "https://smscs-frontend.s3.ap-southeast-1.amazonaws.com/CTA/cta.png"
    const vertical = "bottom"
    const horizontal = "center"

    const initialFieldProps = {name: "", company: "", phone: "", email: "", message: ""}
    const [fields, setFields] = useState<Fields>(initialFieldProps)
    const [loader, setLoader] = useState<boolean>(false)
    const [snackbar, setSnackbar] = useState<boolean>(false)

    const handleNameChange = (e:any) => {
        setFields({
            ...fields,
            name: e.target.value
        })
    }

    const handleCompanyChange = (e:any) => {
        setFields({
            ...fields,
            company: e.target.value
        })
    }

    const handlePhoneChange = (e:any) => {
        setFields({
            ...fields,
            phone: e.target.value
        })
    }

    const handleEmailChange = (e:any) => {
        setFields({
            ...fields,
            email: e.target.value
        })
    }

    const handleMessageChange = (e:any) => {
        setFields({
            ...fields,
            message: e.target.value
        })
    }

    const validateFields = () => {
        let check = false
        let values = Object.values(fields)
        for (let i = 0; i < values.length; i++) {
            if (values[i] === "") {
                check = true
                break
            }
        }
        return check ? true : false
    }

    const send = async () => {
        try {
            setLoader(true)
            var finalString = {
                name: fields.name,
                proposed_company: fields.company,
                phone: fields.phone,
                email: fields.email,
                message: fields.message,    
                nature_business: "",
                business_address: "",
                shareholder_name: "",
                director_name: "",
            }
            await fetch("https://smscs-backend.herokuapp.com/email", {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: 'post',
                body: JSON.stringify(finalString),
            }).then(() => {
                console.log("Email Sent")
                setLoader(false)
                setSnackbar(true)
                setTimeout(() => {
                    setSnackbar(false)
                    // window.location.reload()
                }, 3000)
            })
        }
        catch (e) {
            console.log("Email Error", e)
            setLoader(false)
        }
    }

    return (
        <div className="CTA" id=">">
            {/* Indications */}
            {loader && <Loader />}
            <Snackbar 
                open={snackbar} 
                onClose={() => setSnackbar(false)}
                anchorOrigin={{ vertical, horizontal }}
            >
                <Alert onClose={() => setSnackbar(false)} severity="success" sx={{ width: '100%' }}>
                    Message Sent!
                </Alert>
            </Snackbar>
            <div className="CTA__Inputs">
                <div className="CTA__Inputs__First-section">
                    <p>GET IN TOUCH</p>
                    <p>REACH OUT TO US</p>
                    <Input
                        allowClear
                        placeholder={"Name"}
                        size="large"
                        onChange={handleNameChange}
                    />
                    <Input
                        allowClear
                        placeholder={"Company Name"}
                        size="large"
                        onChange={handleCompanyChange}
                    />
                </div>
                <div className="CTA__Inputs__Second-section">
                    <Input
                        allowClear
                        placeholder={"Phone"}
                        size="large"
                        onChange={handlePhoneChange}
                    />
                    <Input
                        allowClear
                        placeholder={"Email"}
                        size="large"
                        onChange={handleEmailChange}
                    />
                </div>
                <TextArea
                    placeholder="Message"
                    rows={6}
                    allowClear
                    showCount
                    onChange={handleMessageChange}
                />
                <div className="button">
                    <Button 
                        size="large"
                        onClick={send}
                        disabled={validateFields() || loader}
                    >
                        SUBMIT
                    </Button>
                </div>
            </div>
            {includeImage && (
                <div className="CTA__Image">
                    <img
                        src={url}
                        alt={"CTA Image"}
                    />
                </div>
            )}
        </div>
    )

}