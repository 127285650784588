import { Footer } from "../../../Components/Footer/Footer"
import { Navbar } from "../../../Components/Navbar/Navbar"
import { Audit } from "../../../Components/Services/Components/Audit/Audit"

export const Pages_Audit = () => {
    return (
        <>
            <Navbar />
            <Audit />
            <Footer />
        </>
    )
}