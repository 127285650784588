import { Col, Row } from "antd";
import { Button, HashLink } from "../../../../Constants/libraries";
import { homepage_content_first, homepage_content_second, services } from "../../Constants";
import "./HomepageContent.css";

export const HomepageContent = () => {
    const imageUrl = "https://smscs-frontend.s3.ap-southeast-1.amazonaws.com/Homepage/team.png"

    return (
        <div className="HomepageContent" id=">>>">
            <div className="HomepageContent__First">
                <div className="HomepageContent__First__Image">
                    <img
                        src={imageUrl}
                        alt={"Team"}
                    />
                </div>
                <div className="HomepageContent__First__Text">
                    <p className="HomepageContent__First__Text__Heading">WHO WE ARE</p>
                    <p className="HomepageContent__First__Text__Title">Corporate Secretarial Service in Malaysia</p>
                    <p>{homepage_content_first}</p>
                    <Button size="large">
                        <HashLink smooth to="/team#>">WHY CHOOSE US?</HashLink>
                    </Button>    
                </div>
            </div>
            <div className="HomepageContent__Second">
                <p className="HomepageContent__First__Text__Heading">WHAT WE DO</p>
                <p className="HomepageContent__First__Text__Title">Ready to Assist with ALL Your Business Needs</p>
                <p>{homepage_content_second}</p>
            </div>
            <div className="HomepageContent__Third">
                <Row gutter={[4, 4]}>
                    {services.map((item, index) => (
                        <Col 
                            xl={8} 
                            lg={8}
                            md={12}
                            sm={12}
                            xs={24}
                            className={`${index === 0 ? "full-width" : ""}`}
                            key={index}
                        >
                            <div
                                key={index} 
                                className="HomepageContent__Third__Container"
                                style={{
                                    background: "#000000",
                                    backgroundImage: `url(${item.img}`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: item.img_alignment ? item.img_alignment : "center",
                                    backgroundSize: "cover",
                                }}
                            >
                                <div className="HomepageContent__Third__Container__Overlay"></div>
                                <HashLink smooth to={`/services${item.link}#>`}>
                                    <div className="HomepageContent__Third__Container__Overlay-cancel">
                                        <p>{item.title}</p>
                                        <a>{"View More"}</a>
                                    </div>
                                </HashLink>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    )
}