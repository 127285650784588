import { Footer } from "../../Components/Footer/Footer"
import { Navbar } from "../../Components/Navbar/Navbar"
import { PrivacyPolicy } from "../../Components/PrivacyPolicy/PrivacyPolicy"

export const Pages_PrivacyPolicy = () => {
    return (
        <>
            <Navbar />
            <PrivacyPolicy />
            <Footer />
        </>
    )
}