import SMS_LOGO_NO_TAGLINE from "../../../Images/SMS-Logo-NoTagline.png"
import { Link, Menu } from '../../../Constants/libraries'
import { navigation } from '../Constants'
import "./NavbarMobile.css"

export const NavbarMobile = () => {
    return (
        <div className="NavbarMobile">
            <div className="NavbarMobile__Logo">
                <Link to={"/"}>
                    <img 
                        src={SMS_LOGO_NO_TAGLINE} 
                        alt="SMSCS Logo"
                        width={120}
                    />
                </Link>
            </div>
            <Menu>
                <Link to="/">
                    <img 
                        src={SMS_LOGO_NO_TAGLINE} 
                        alt="SMSCS Logo"
                        width={150}
                    />
                </Link>
                {navigation.map((item, index) => (
                    <Link key={index} to={item.link}>{item.text}</Link>
                ))}
            </Menu>
        </div>
    )
}