export const services = [
    {
        img: "https://smscs-frontend.s3.ap-southeast-1.amazonaws.com/Services/Services_All_Thumbnails_Resized/services_building.jpg",
        title: "COMPANY INCORPORATION & FOUNDATION SET UP",
        description: "SMS Corporate has assisted both Malaysians and Foreigners to register business entities commonly Sdn Bhd and Foundations.",
        link: "/company-incorporation"
    },
    {
        img: "https://smscs-frontend.s3.ap-southeast-1.amazonaws.com/Services/Services_All_Thumbnails_Resized/services_secreterial.jpg",
        title: "COMPANY SECRETARIAL" ,
        description: "Our core service includes a full range of company secretarial services to clients throughout Malaysia which we can proudly boast of a wide and thorough knowledge of 30 years and more.",
        link: "/company-secretarial"
    },
    {
        img: "https://smscs-frontend.s3.ap-southeast-1.amazonaws.com/Services/Services_All_Thumbnails_Resized/services_accounting.jpg",
        title: "ACCOUNTING & BOOKKEEPING",
        description: "We provide accounting and software services for complete bookkeeping for specific reports ranging from management and year-end reports to a worry-free audit analysis.",
        link: "/accounting"
    },
    {
        img: "https://smscs-frontend.s3.ap-southeast-1.amazonaws.com/Services/Services_All_Thumbnails_Resized/services_audit.jpg",
        title: "AUDIT SUPPORT SERVICES",
        description: "We provide service to assist you with the annual statutory audit as well as any other special assurance engagement such as forensic accounting.",
        link: "/audit"
    },
    {
        img: "https://smscs-frontend.s3.ap-southeast-1.amazonaws.com/Services/Services_All_Thumbnails_Resized/services_tax.jpg",
        title: "TAX ADVISORY & COMPLIANCE",
        description: "We have a team of tax consultants who can advise you with PCB payments, SST, Corporate Tax, and Transfer Pricing to Withholding Tax.",
        link: "/tax"
    },
    {
        img: "https://smscs-frontend.s3.ap-southeast-1.amazonaws.com/Services/Services_All_Thumbnails_Resized/services_advisor.jpg",
        title: "FINANCIAL ADVISORY",
        description: "We provide consultancy services on current market trends and financial due diligence, valuation, and fundraising.",
        link: "/financial"
    },
    {
        img: "https://smscs-frontend.s3.ap-southeast-1.amazonaws.com/Services/Services_All_Thumbnails_Resized/services_trademark.jpg",
        title: "TRADEMARK REGISTRATION SERVICES",
        description: "We have completed many registrations of Trademarks throughout our service and still, it remains quite popular under our services.",
        link: "/trademark"
    },
    {
        img: "https://smscs-frontend.s3.ap-southeast-1.amazonaws.com/Services/Services_All_Thumbnails_Resized/services_permit.jpg",
        title: "WTA & WORK PERMIT SERVICES",
        description: "We provide services relating to your WTA and Work Permit concerns by linking you to our highly reviewed and rated connections.",
        img_alignment: "bottom",
        link: "/permit"
    },
    {
        img: "https://smscs-frontend.s3.ap-southeast-1.amazonaws.com/Services/Services_All_Thumbnails_Resized/services_payroll.jpg",
        title: "PAYROLL OUTSOURCING",
        description: "We can provide comprehensive HR solutions that include monthly payroll, Employees Provident Fund (EPF), and PERKESO (SOCSO) contributions.",
        hover_animation: "imghvr-slide-left",
        link: "/payroll"
    },
    {
        img: "https://smscs-frontend.s3.ap-southeast-1.amazonaws.com/Services/Services_All_Thumbnails_Resized/services_communication.jpg",
        title: "CORPORATE COMMUNICATION SERVICES",
        description: "We provide the creation of corporate communications materials such as company profiles, director profiles, memorandum of understanding (MoU), agreements, and memos.",
        link: "/communication"
    }
]

export const services_heading = <>With over 30 years of experience, we are ready to assist you with <span>ALL</span> your business needs on corporate services.</>

export const services_ending = `Looking for assistance? Get in touch with us today to learn how we can help you`
