import { HashLink } from "../../../../Constants/libraries"
import { services } from "../../Constants/index"

import "./ServicesMap.css"

export const ServicesMap = () => {
    return (
        <div className="Services__Description">
            {services.map((item, index) => (
                <figure 
                    key={index}
                    className={`imghvr-fade Services__Description__Individual`}
                    style={{
                        background: "#000000",
                        backgroundImage: `url(${item.img}`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: item.img_alignment ? item.img_alignment : "center",
                        backgroundSize: "cover",
                    }}
                >
                    {/* Overlay */}
                    <div className="Services__Description__Individual__Overlay"></div>

                    {/* Animation Content */}
                    <div className="Services__Description__Individual__Overlay-cancel">
                        <p>{item.title}</p>
                        <figcaption>
                            <HashLink smooth to={`/services${item.link}#>`}>
                                <p>{item.description}</p>
                                <p>View More</p>
                            </HashLink>
                        </figcaption>
                    </div>
                </figure>
            ))}   
        </div>
    )
}