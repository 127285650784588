export const title = `FINANCIAL ADVISORY`

export const content_first_p1 = `
    When considering business expansion let us help you in navigating what options are suitable for you to venture into. Our team advises private limited companies,family-owned businesses, and other organizations in Malaysia.
`

export const content_second_title = `We can help with:`

export const content_second_bullets = [
    "Navigating bank loans and grants",
    "Financial due diligence", 
    "Share valuation",
    "Mergers and acquisition"
]

export const content_third_p1 = `
    At SMS Corporate Services, we work with a team of professionals on secretarial tasks. Our team members consist of experienced Company Secretaries, Chartered Accountants, and Tax Consultants to cater to our clients’ needs. Rest assured that our expertise in accounting and financial services will keep your business up-to-date with Malaysia’s standard corporate governance practices. 
`