export const title = `CORPORATE COMMUNICATIONS SERVICES`

export const content_first_p1 = `
    It is difficult to overstate the critical importance of effective communication materials for most businesses and organizations. Through effective communication pieces, you are able to best showcase your company’s products or services and what makes brands unique, credible, and trusted in comparison to their competitors. Types of corporate communications material include: 
`

export const content_first_bullets = [
    "Company Profile/ Overview", 
    "Director Profile", 
    "Memorandum of Understandings (MoU)",
    "Agreements", 
    "Memos" 
]

export const content_second_title = `
    What is a Company Profile? 
`

export const content_second_p1 = `
    A company profile provides your business plan with basic background information about your company so they have an understanding of what you do, who the management team is, and what customers your business serves. The company description is the second piece of a business plan, falling right after the executive summary. Similar to the executive summary, your company overview will be short and succinct. The profile needs to showcase what your business does and who your customers are, even if they have limited time.
`

export const content_third_title = `
    Why do I need a company profile?
`

export const content_third_p1 = `
    The company overview is the part of your business plan that gives the basics and background of your business. It’s the foundation on which you will build the rest of your business plan.
`

export const content_third_p2 = `
    If you’re looking to appeal to investors or potential clients, you need a reader to make an informed decision about your company. Before they can do that, they must know what your company does and who your customer is. Lenders in particular need a reason to keep reading since they see tons of business plans regularly. The company profile provides those answers, and it will help you get a better sense of your business so you can firm up things like your marketing plan. 
`